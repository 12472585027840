import { firebase } from '../firebase/firebase';
import database from '../firebase/firebase';

// signup action
export const signup = (uid) => ({
	type: 'SIGNUP',
	uid: uid
})

// Action that starts the signup process
export const startSignup = (email, password) => {
	return () => {
		// we use 'return' so this function returns a promise chain
		return firebase.auth().createUserWithEmailAndPassword(email, password);
	}
}

// Action to add a user to the Redux store
// Dispatchs an object to the Redux store and changes the store
export const addUser = (user) => ({
	type: 'ADD_USER',
	user: user
});

// startAddUser starts that process of dispatching an object to the Redux store, 
// It returns a function (instead of an object) that gets called internally by redux, and it gets called with 'dispatch' as a parameter so we can use it inside of the function. 
// Doing this wouldn't work without the Thunk middleware
export const startAddUser = ( user = {} ) => {
	// set defaults by destructuring user
	const {
		email = '',
		isAdmin = false,
		uid = user.uid
	} = user;

	return (dispatch) => { // this return function has access to dispatch
		// Set up user object to be pushed to Firebase and dispatched to the store
		const user = { 
			email,
			isAdmin,
		};

		// Push user to Firebase
		// we 'return' the call to the database call so we can return a promise chain for Jest testing
		return database.ref('users/' + uid).set(user).then((ref) => { //ref to the child location
			dispatch(addUser({ //dispatch action to the redux store
				id: uid, 
				...user 
			}));

			firebase.auth().currentUser.sendEmailVerification({
				url: 'http://directory.l-s.com'
			});
		})
	}
}