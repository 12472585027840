import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startSignup, startAddUser } from '../actions/signup';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo-white.png';

class SignupPage extends Component {
	// this is a class component since we need to handle its state in order to store the values from the form
	constructor(props){
		super(props);

		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			isAdmin: false,
			error: null
		}
	}

	// Class methods that will change the state values as input values change
	emailOnChange = (e) => {
		const email = e.target.value;
		this.setState( () => ( { email: email } ) );
	};
	passwordOnChange = (e) => {
		const password = e.target.value;
		this.setState( () => ( { password: password } ) );
	};
	confirmPasswordOnChange = (e) => {
		const confirmPassword = e.target.value;
		this.setState( () => ( { confirmPassword: confirmPassword } ) );
	};

	onFormSubmit = (e) => {
		e.preventDefault();

		if (this.state.email.indexOf('@l-s.com') === -1) {
			this.setState( () => ( { error: 'Please make sure you\'re using a valid L&S email.'} ) );
		} else if (this.state.password !== this.state.confirmPassword) {
			this.setState( () => ( { error: 'Passwords do not match.'} ) );
		} else {
			// the action startSignup returns a promise chain, that way we can use then() and catch() 
			// we have access to dispatch since components have access to it by default when connected to the Redux store through connect()
			this.props.dispatch(startSignup(this.state.email, this.state.password)).then( (authUser) => {
				this.props.history.push('/');
				this.props.dispatch(startAddUser({ uid: authUser.user.uid, email: this.state.email, isAdmin: this.state.isAdmin }));
			})
			.catch((error) => {
				this.setState( () => ( { error: error.message } ) );
			});
		}
	}

	render() {
		return (
			<div className="login-container">
				<form className="login-form">
					<div className="login-card">
						<div className="login-card__logo">
							<img src={logo} alt="Logo"/>
						</div>
						<h2 className="login-card__title--small-spacing">Sign Up</h2>
						<span className="login-card__subtitle">You will be asked to verify your email after registration.</span>
						<input className="login-card__input" value={this.state.email} type="text" placeholder="Email" onChange={this.emailOnChange} /><br/>
						<input className="login-card__input" value={this.state.password} type="password" placeholder="Password" onChange={this.passwordOnChange} /><br/>
						<input className="login-card__input" value={this.state.confirmPassword} type="password" placeholder="Confirm Password" onChange={this.confirmPasswordOnChange} /><br/>
						{this.state.error && <p className="login-card__error">{this.state.error}</p>}
						<button className="btn btn-primary login-card__button" onClick={ this.onFormSubmit }>Sign Up</button>
						<span className="login-card__back">Already have an account? <NavLink to="/login">Log in.</NavLink></span>
					</div>
				</form>
			</div>
		)
	}
}
export default connect()(SignupPage);