// Signup reducer

const signupReducerDefaultState = [];

// Params (Array: state of entries array, Obj: action)
const signupReducer = (state = signupReducerDefaultState, action) => { 
	switch(action.type){
		case 'SIGNUP':
			return [{ //returns object with firebase user id
				uid: action.uid
			}]
		case 'ADD_USER':
			return [...state, action.user];
		default:
			return state;
	}
};
export default signupReducer;
