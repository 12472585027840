import React from 'react';
import { connect } from 'react-redux';

const EmailButtons = (props) => (
  <div className="email-send">
  {props.isAuthenticated && props.isAdmin && props.type === 'person' && props.list.length > 0 &&
   <a href={'mailto:' + props.list}>mass email {props.team}</a>
  }
  </div>
)

const mapStateToProps = (state) => {
	return {
		isAuthenticated: !!state.auth.uid,
		isAdmin: state.auth.isAdmin
	}
}

// we wrap that component in a withRouter to remove the blocked updates, this is donde to allow the 'sidebar-link-is-active' class on NavLink to update
// https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
export default connect(mapStateToProps)(EmailButtons);