import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo-white.png';

class VerifyPage extends Component {
	// this is a class component since we need to handle its state in order to store the values from the form
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="login-container">
				<form className="login-form">
					<div className="login-card">
						<div className="login-card__logo">
							<img src={logo} alt="Logo" />
						</div>
						<h2 className="login-card__title">Verify Your Email</h2>
						<span className="login-card__back">We sent you a confirmation link to your email address. If you have already verified your account, <NavLink to="/login">click here to log in.</NavLink></span>
					</div>
				</form>
			</div>
		)
	}
}
export default VerifyPage;
